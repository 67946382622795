import "./jquery.visible.min";
var $window;
var $navbar;
var $header;
var $navbarheight = 160;
var $navbarminheight = 50;
var $navbarsubtop = 147;
var $navbarsubtopmin = 103;
var $logoheight = 97;
var $logominheight = 45;
var $logo;
var $navbarsub;
var $navbarshadow;


$(document).ready(function() {
    $window = $(window);
    $navbar = $('nav');
    $logo = $('.logo-page');
    $navbarshadow = $('.header--shadow');
    $navbarsub = $('.dropdown-menu');
    initContent();
});

function initContent() {
    animateContent();
    $navbarsub.css('top', $navbarsubtop + 'px');
    $window.on('scroll', function (e) {
        animateScrollSlower(e);
        if($(window).width() > 1199) {
            animateMenue(e);
        } else {
            animateMenueMobil(e);
        }
    });

    initTeaserSlider(true);
    initAppartementUebersicht(true);

    $('.dropdown-toggle').on('click', function() {
        var obj = $(this).next();
        obj.css('opacity', 0);
        setTimeout(function() {
            obj.css('transition', 'opacity 0.3s');
            obj.css('opacity', 1);
        }, 50);
    });

    parallax();
    $(window).on('scroll', function() {
        parallax();
    });

    var iconname = '';
    var pfad = '';
    $('.fontawsome-icon').each(function() {
       iconname = $(this).data('iconname');
       pfad = $(this).children().attr('src');
       $(this).load(pfad);
    });
    $('.schalter').on('click', function() {
        schalterClicked();
    });

    var tvideo = $('#teaservideo');
    if($(window).outerWidth() < 768) {
        tvideo.attr('src', tvideo.data('srcmobile'));
    } else {
        tvideo.attr('src', tvideo.data('src'));
    }
    tvideo[0].load();

    $('.appartments--details-btn').on('click', function() {
        var video = $(this).data('url');
        if($(window).outerWidth() < 768) {
            video = $(this).data('urlmobil');
        }
        $('.videooverlay').remove();
        $('body').append('<div id="videooverlay"><div id="closevideoverlay"></div><div class="embed-responsive embed-responsive-16by9"><video id="detailsvideo" autoplay="true" loop="true" preload="auto" muted="" playsinline src="'+video+'" </div></div>');
        setTimeout(function() {
            $('#videooverlay').addClass('videooverlayeffekt');
        }, 50);
        $('#closevideoverlay').off('click').on('click', function() {
           $('#videooverlay').remove();
        });
    });
}

function schalterClicked() {
    var $clicked = $('.schalter-clicked');
    if($clicked.visible() === false) {
        $clicked.show();
        $('body').addClass('clicked');
    } else {
        $clicked.hide();
        $('body').removeClass('clicked');
    }
}

function animateContent() {
    $('.ani-fade').each( function() {
       $(this).addClass('ani-fade-in');
    });
}

function animateScrollSlower(e) {
    var scrollhoehe = e.currentTarget.scrollY / 3;

    $('.slowerscroll').css('transform','translateY(+'+scrollhoehe+'px)');
}

function animateMenue(e) {
    var hoehe = 150;
    var hoeheLogo = 120;
    var topLogo = 15;
    var scrollhoehe = e.currentTarget.scrollY;

    hoehe = hoehe - scrollhoehe / 4;
    hoeheLogo = hoeheLogo - scrollhoehe / 4.5;
    topLogo = topLogo - scrollhoehe / 50;
    if(hoehe < 60) {
        hoehe = 60;
    }

    if(hoeheLogo < 40)
        hoeheLogo = 40;

    if(topLogo < 10)
        topLogo = 10;

    $navbar.css('height', hoehe+'px');
    $logo.css('height', hoeheLogo+'px');
    $logo.css('top', topLogo+'px');

    var navsubtoptmp = $navbarsubtop - (scrollhoehe / 8);
    if (navsubtoptmp < $navbarsubtopmin) navsubtoptmp = $navbarsubtopmin;

    $navbarsub.css('top', navsubtoptmp + 'px');
}

function animateMenueMobil(e) {

}


function initTeaserSlider(start = false) {
    if(start !== true)
        $('.bildergalerie--article').each(function(index) {
            var tmp = $(this);
            var activeIndex = 0;
            var fertig = false;
            if(tmp.visible()) {
                console.log("SICHTBAR!!");
                tmp.children('.teaser--bg').each(function (index2) {
                    var aktuell = $(this);
                    if (aktuell.hasClass('active') === true && fertig === false) {
                        if (aktuell.next().length) {
                            aktuell.removeClass('active');
                            aktuell.next().addClass('active');
                            fertig = true;
                        } else {
                            aktuell.removeClass('active');
                            aktuell.parent().children(':first').addClass('active');
                            fertig = true;
                        }
                    }
                })
            }
        });
    setTimeout(function() {
        initTeaserSlider();
    }, 5000);
}

function parallax() {
    $('.parallax').each(function() {
        var $elem = $(this);

        if($elem.visible(true)) {
            var parent_top = $elem.offset().top;
            var window_bottom = $(window).scrollTop();
            var oVal = ((window_bottom - parent_top) / 3);

            if ($elem.parent().hasClass('teaser--halb')) {
                // oVal = ((window_bottom - parent_top) / 3);
            }

            var tmp = parseInt($elem.height()) * -1;
            if(tmp > oVal)
                oVal = tmp;

            $elem.children().css('transform', 'translate3d(0px, ' + oVal + 'px, 0px)');
        }
    });

    $('.parallaxslower').each(function() {
        var $elem = $(this);

        var picheight = $elem.children().children().children('img').outerHeight();
        $elem.children().css('height', picheight+'px');

        if($elem.visible(true)) {
            var parent_top = parseInt($elem.offset().top);
            var window_bottom = parseInt($(window).scrollTop());
            var oVal = ((window_bottom - parent_top)) + (parseInt($elem.outerHeight()) * 1.5);

            if(oVal < 0)
                oVal = 0;

            $elem.children().css('transform', 'translate3d(0px, ' + oVal + 'px, 0px)');
        }
    });

    $('.parallax-horizontal').each(function() {
        var $elem = $(this);

        if($elem.visible(true)) {
            var parent_top = $elem.offset().top;
            var window_bottom = $(window).scrollTop();
            var oVal = ((window_bottom - parent_top) / 15);

            $elem.children().css('transform', 'translate3d(' + oVal + 'px, 0px, 0px)');
        }
    });
}

var wasClicked = false;
function initAppartementUebersicht(first = false) {
    if(wasClicked !== false)
        return false;

    if(first === true) {
        $('.bilder--slider').children().each(function(index) {
            $(this).data('picindex', index);
        });
    }

    if(first !== true && $('.bilder--slider').visible(true)) {
        $('.bilder--slider').each(function (index) {
            var obj = $(this);
            var fertig = false;
            obj.children().each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            });
        });
        $('.bilder-slider-preview-btn').each(function (index) {
            var obj = $(this);

            var fertig = false;
            obj.children().each(function (index2) {
                var aktuell = $(this);
                if (aktuell.hasClass('active') === true && fertig === false) {
                    if (aktuell.next().length) {
                        aktuell.removeClass('active');
                        aktuell.next().addClass('active');
                        fertig = true;
                    } else {
                        aktuell.removeClass('active');
                        aktuell.parent().children(':first').addClass('active');
                        fertig = true;
                    }
                }
            });
        });
    }

    setTimeout(function() {
        if(wasClicked === false) {
            initAppartementUebersicht(false);
        } else {
            wasClicked = false;
            setTimeout(function() {
                initAppartementUebersicht(false);
            }, 3000);
        }
    }, 3000);
}